// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconInputAi } from '@tabler/icons-react'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { PERMISSION } from '../../constants'

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconInputAi
}

const premissionsAll = [
  PERMISSION.ADMIN,
  PERMISSION.GIAMDOC,
  PERMISSION.HCNS,
  PERMISSION.KETOAN,
  PERMISSION.SALE,
  PERMISSION.TUYENDUNG
]
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Utilities',
  type: 'group',
  premissions: premissionsAll,
  children: [
    {
      id: 'util-typography',
      title: 'Typography',
      type: 'item',
      url: '/utils/util-typography',
      icon: icons.IconTypography,
      breadcrumbs: false,
      premissions: premissionsAll
    },
    {
      id: 'util-color',
      title: 'Color',
      type: 'item',
      url: '/utils/util-color',
      icon: icons.IconPalette,
      breadcrumbs: false,
      premissions: premissionsAll
    },
    {
      id: 'util-shadow',
      title: 'Shadow',
      type: 'item',
      url: '/utils/util-shadow',
      icon: icons.IconShadow,
      breadcrumbs: false,
      premissions: premissionsAll
    },
    {
      id: 'util-input',
      title: 'Input',
      type: 'item',
      url: `/${ROUTES.UTILS}/${ROUTES.UTILS_CHILD.INPUT}`,
      icon: icons.IconInputAi,
      breadcrumbs: false,
      premissions: premissionsAll
    }
  ]
}

export default utilities
