import { MenuItem } from '../../types'
import {
  RiBarChartBoxLine,
  RiCaravanLine,
  RiFunctionLine,
  RiProfileLine,
  RiShakeHandsLine,
  RiTeamLine,
  RiTodoFill,
  RiTodoLine
} from '@remixicon/react'
import {
  IconBoxAlignBottomLeft,
  IconBriefcase,
  IconBuilding,
  IconCar,
  IconCarCrane,
  IconCoinBitcoin,
  IconUser,
  IconUsersGroup
} from '@tabler/icons-react'
import { PERMISSION } from '../../constants'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { createCollapseGroup, createItem } from '../../help'

const icons = {
  RiFunctionLine,
  RiProfileLine,
  RiBarChartBoxLine,
  RiTodoFill,
  RiTodoLine,
  RiTeamLine,
  RiShakeHandsLine,
  RiCaravanLine,
  IconUsersGroup,
  IconCoinBitcoin,
  IconBuilding,
  IconUser,
  IconBriefcase,
  IconCarCrane,
  IconBoxAlignBottomLeft,
  IconCar
}

export const generalMenuItem: MenuItem[] = [
  createCollapseGroup({
    id: 'list-general',
    title: 'Quản lý chung',
    icon: icons.RiTodoLine,
    iconActive: icons.RiTodoFill,
    premissions: [PERMISSION.ADMIN, PERMISSION.GIAMDOC, PERMISSION.HCNS, PERMISSION.KETOAN],
    children: [
      createItem({
        id: 'general-request',
        title: 'Yêu cầu',
        url: `/${ROUTES.STATISTICAL}/${ROUTES.STATISTICAL_CHILD.REQUEST}`,
        premissions: [
          PERMISSION.ADMIN,
          PERMISSION.GIAMDOC,
          PERMISSION.HCNS,
          PERMISSION.KETOAN,
          PERMISSION.SALE,
          PERMISSION.TUYENDUNG
        ]
      }),
      createItem({
        id: 'general-base-price',
        title: 'Giá cơ sở',
        url: `/${ROUTES.STATISTICAL}/${ROUTES.STATISTICAL_CHILD.BASE_PRICE}`,
        premissions: [
          PERMISSION.ADMIN,
          PERMISSION.GIAMDOC,
          PERMISSION.HCNS,
          PERMISSION.KETOAN,
          PERMISSION.SALE,
          PERMISSION.TUYENDUNG
        ]
      })
    ]
  })
]
