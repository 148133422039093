import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, POST, PUT } from '../../../common/contants'
import { axiosBaseQuery } from '../../baseQuery'
import { ReponseData2 } from '../../../types'
import { StaffType } from '../../../types/staffType'

export const staffTypeApi = createApi({
  reducerPath: 'staffTypeApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['StaffType'], // Đảm bảo tagType là 'StaffType'
  endpoints: (builder) => ({
    getListStaffType: builder.query({
      query: (params) => ({
        url: NetWork.staffType,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: StaffType[] }>) =>
          result ? [{ type: 'StaffType', id: 'LIST' }] : [] // Cung cấp tag 'StaffType' với id 'LIST'
      })
    }),
    getStaffTypeById: builder.query({
      query: (params: { staffTypeId: number }) => ({
        url: NetWork.staffTypeId(params.staffTypeId),
        method: GET
      })
    }),
    addStaffType: builder.mutation({
      query: (data) => ({
        url: NetWork.staffType,
        method: POST,
        data: data
      })
    }),
    updateStaffType: builder.mutation({
      query: (data) => ({
        url: NetWork.staffTypeId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteStaffType: builder.mutation({
      query: (data: { ids: Array<number> }) => ({
        url: NetWork.staffType,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'StaffType', id: 'LIST' }] // Vô hiệu hóa tag 'StaffType' với id 'LIST' để gọi lại getListStaffType
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListStaffTypeQuery,
  useDeleteStaffTypeMutation,
  useAddStaffTypeMutation,
  useGetStaffTypeByIdQuery,
  useUpdateStaffTypeMutation
} = staffTypeApi
