import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { NormsSalaryMonthType } from '../../../../types/norms/normsSalaryMonth'
import { axiosBaseQuery } from '../../../baseQuery'

export const normsSalaryMonthApi = createApi({
  reducerPath: 'normsSalaryMonthApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['NormsSalaryMonth'],
  endpoints: (builder) => ({
    getListNormsSalaryMonth: builder.query({
      query: (params) => ({
        url: NetWork.normsSalaryMonth,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: NormsSalaryMonthType[] }>) =>
          result ? [{ type: 'NormsSalaryMonth', id: 'LIST' }] : []
      })
    }),
    getNormsSalaryMonthById: builder.query({
      query: (params: { normsSalaryMonthId: number }) => ({
        url: NetWork.normsSalaryMonthId(params.normsSalaryMonthId),
        method: GET
      })
    }),
    addNormsSalaryMonth: builder.mutation({
      query: (data) => ({
        url: NetWork.normsSalaryMonth,
        method: POST,
        data: data
      })
    }),
    updateNormsSalaryMonth: builder.mutation({
      query: (data) => ({
        url: NetWork.normsSalaryMonthId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteNormsSalaryMonth: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.normsSalaryMonth,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'NormsSalaryMonth', id: 'LIST' }] // Vô hiệu hóa tag 'NormsSalaryMonth' với id 'LIST' để gọi lại getListNormsSalaryMonth
      })
    }),
    changeStatusNormsSalaryMonth: builder.mutation({
      query: (data: { normsSalaryMonthId: number; params: { isActive: boolean } }) => ({
        url: NetWork.normsSalaryMonthId(data.normsSalaryMonthId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListNormsSalaryMonthQuery,
  useDeleteNormsSalaryMonthMutation,
  useAddNormsSalaryMonthMutation,
  useGetNormsSalaryMonthByIdQuery,
  useUpdateNormsSalaryMonthMutation,
  useChangeStatusNormsSalaryMonthMutation
} = normsSalaryMonthApi
