import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { ShellTType } from '../../../types/shell'
import { axiosBaseQuery } from '../../baseQuery'

export const paymentMethodApi = createApi({
  reducerPath: 'paymentMethodApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['PaymentMethod'],
  endpoints: (builder) => ({
    getListPaymentMethod: builder.query({
      query: (params) => ({
        url: NetWork.paymentMethod,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: ShellTType[] }>) =>
          result ? [{ type: 'PaymentMethod', id: 'LIST' }] : []
      })
    }),
    getPaymentMethodById: builder.query({
      query: (params: { paymentMethodId: number }) => ({
        url: NetWork.paymentMethodId(params.paymentMethodId),
        method: GET
      })
    }),
    addPaymentMethod: builder.mutation({
      query: (data) => ({
        url: NetWork.paymentMethod,
        method: POST,
        data: data
      })
    }),
    updatePaymentMethod: builder.mutation({
      query: (data) => ({
        url: NetWork.paymentMethodId(data.id),
        method: PUT,
        data: data
      })
    }),
    deletePaymentMethod: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.paymentMethod,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'PaymentMethod', id: 'LIST' }] // Vô hiệu hóa tag 'PaymentMethod' với id 'LIST' để gọi lại getListPaymentMethod
      })
    }),
    changeStatusPaymentMethod: builder.mutation({
      query: (data: { paymentMethodId: number; params: { isActive: boolean } }) => ({
        url: NetWork.paymentMethodId(data.paymentMethodId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListPaymentMethodQuery,
  useDeletePaymentMethodMutation,
  useAddPaymentMethodMutation,
  useGetPaymentMethodByIdQuery,
  useUpdatePaymentMethodMutation,
  useChangeStatusPaymentMethodMutation
} = paymentMethodApi
