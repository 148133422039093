import { RiTeamLine } from '@remixicon/react'
import { PERMISSION } from '../../constants'
import { createItem } from '../../help'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { MenuItem } from '../../types'

const icons = {
  RiTeamLine
}

export const staffAccountMenuItem: MenuItem = createItem({
  id: 'list-staff-account',
  title: 'Tài khoản nhân viên',
  url: `/${ROUTES.CATEGORY}/${ROUTES.CATEGORY_CHILD.STAFF}`,
  icon: icons.RiTeamLine,
  premissions: [PERMISSION.ADMIN, PERMISSION.GIAMDOC, PERMISSION.HCNS, PERMISSION.KETOAN]
})
