import PropTypes from 'prop-types'

// material-ui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import { useTheme } from '@mui/material/styles'

// project imports
import LogoSection from '../LogoSection'
import ProfileSection from './ProfileSection'

// assets
import { IconMenu2 } from '@tabler/icons-react'
import NotificationSection from './NotificationSection'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }: { handleLeftDrawerToggle: () => void }) => {
  const theme = useTheme()

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          // width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '8px', overflow: 'hidden' }}>
          <Avatar
            variant='rounded'
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.common.white,
              color: theme.palette.common.black
              // '&:hover': {
              //   background: theme.palette.secondary.dark,
              //   color: theme.palette.secondary.light
              // }
            }}
            onClick={handleLeftDrawerToggle}
            color='inherit'
          >
            <IconMenu2 stroke={1.5} size='1.3rem' />
          </Avatar>
        </ButtonBase>
        <Box component='span' sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, ml: '20px' }}>
          <LogoSection />
        </Box>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box component='span' sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, ml: 4 }}>
        {/* <Typography variant='h3' sx={{ textTransform: 'uppercase' }}>
          SMART TRANSPORT LINK JOINT STOCK COMPANY
        </Typography>
        <Typography variant='caption'>Số 6 tổ 1 Nam Sơn, Phường Đằng Giang, Quận Ngô Quyền, Hải Phòng.</Typography> */}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection />
    </>
  )
}

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
}

export default Header
