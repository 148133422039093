import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'

// project imports
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'
import { MenuItem } from '../../../../../../types'
import { useAppSelector } from '../../../../../../app/hooks'
import { authStore, customTheme } from '../../../../../../app/selectedStore'

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }: { item: MenuItem }) => {
  const theme = useTheme()
  const role = useAppSelector(authStore)?.user?.staffTypes
  const customization = useAppSelector(customTheme)
  const openDrawer = customization?.opened
  // menu list collapse & items
  const items = item.children?.map((menu: MenuItem) => {
    if (role?.some((v) => item?.premissions?.includes(v.code))) {
      switch (menu.type) {
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} level={1} />
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1} />
        default:
          return (
            <Typography key={menu.id} variant='h6' color='error' align='center'>
              Menu Items Error
            </Typography>
          )
      }
    }
  })

  return (
    <>
      <List
        subheader={
          item.title &&
          openDrawer && (
            <Typography variant='caption' sx={{ ...theme.typography.menuCaption }} display='block' gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant='caption' sx={{ ...theme.typography.subMenuCaption }} display='block' gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  )
}

NavGroup.propTypes = {
  item: PropTypes.object
}

export default NavGroup
