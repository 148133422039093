import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { ShellTType } from '../../../types/shell'
import { axiosBaseQuery } from '../../baseQuery'

export const currencyApi = createApi({
  reducerPath: 'currencyApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Currency'],
  endpoints: (builder) => ({
    getListCurrency: builder.query({
      query: (params) => ({
        url: NetWork.currency,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: ShellTType[] }>) =>
          result ? [{ type: 'Currency', id: 'LIST' }] : []
      })
    }),
    getCurrencyById: builder.query({
      query: (params: { currencyId: number }) => ({
        url: NetWork.currencyId(params.currencyId),
        method: GET
      })
    }),
    addCurrency: builder.mutation({
      query: (data) => ({
        url: NetWork.currency,
        method: POST,
        data: data
      })
    }),
    updateCurrency: builder.mutation({
      query: (data) => ({
        url: NetWork.currencyId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCurrency: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.currency,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'Currency', id: 'LIST' }] // Vô hiệu hóa tag 'Currency' với id 'LIST' để gọi lại getListCurrency
      })
    }),
    changeStatusCurrency: builder.mutation({
      query: (data: { currencyId: number; params: { isActive: boolean } }) => ({
        url: NetWork.currencyId(data.currencyId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCurrencyQuery,
  useDeleteCurrencyMutation,
  useAddCurrencyMutation,
  useGetCurrencyByIdQuery,
  useUpdateCurrencyMutation,
  useChangeStatusCurrencyMutation
} = currencyApi
