import { createSlice } from '@reduxjs/toolkit'
import { FormValuesCustomer } from '../../../types/customer'

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    tab: 0,
    isEdit: false,
    dataSave: {} as FormValuesCustomer
  },
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload
    },
    setEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setDataSave: (state, action) => {
      state.dataSave = action.payload
    }
  }
})

export const { setTab, setEdit, setDataSave } = customerSlice.actions

export default customerSlice.reducer
