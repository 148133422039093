import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { axiosBaseQuery } from '../../baseQuery'
import { ReponseData2 } from '../../../types'
import { Driver } from '../../../types/driver'
// import { ReponseData } from '../../types'

export const driverApi = createApi({
  reducerPath: 'driverApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Driver'], // Đảm bảo tagType là 'Driver'
  endpoints: (builder) => ({
    getListDriver: builder.query({
      query: (params) => ({
        url: NetWork.driver,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: Driver[] }>) => (result ? [{ type: 'Driver', id: 'LIST' }] : []) // Cung cấp tag 'Driver' với id 'LIST'
      })
    }),
    getDriverById: builder.query({
      query: (params: { driverId: number }) => ({
        url: NetWork.driverId(params.driverId),
        method: GET
      })
    }),
    addDriver: builder.mutation({
      query: (data) => ({
        url: NetWork.driver,
        method: POST,
        data: data
      })
    }),
    updateDriver: builder.mutation({
      query: (data) => ({
        url: NetWork.driverId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteDriver: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.driver,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'Driver', id: 'LIST' }] // Vô hiệu hóa tag 'Driver' với id 'LIST' để gọi lại getListDriver
      })
    }),
    changeStatusDriver: builder.mutation({
      query: (data: { driverId: number; params: { isActive: boolean } }) => ({
        url: NetWork.driverId(data.driverId),
        method: PATCH,
        params: data.params
      })
    }),
    uploadFileDriver: builder.mutation({
      query: (data) => ({
        url: NetWork.uploadDriver(data.path),
        method: POST,
        data: data.values,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListDriverQuery,
  useDeleteDriverMutation,
  useAddDriverMutation,
  useGetDriverByIdQuery,
  useUpdateDriverMutation,
  useChangeStatusDriverMutation,
  useUploadFileDriverMutation
} = driverApi
