import { Icon, IconProps } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { MenuItem } from '../types'
import { RemixiconComponentType } from '@remixicon/react'

export const convertDateToApi = (value: Date | string) => {
  const date = dayjs(value).format()
  return date || ''
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function removeNullOrEmpty<T extends Record<string, any>>(obj: T): Partial<T> {
  const result: Partial<T> = {}

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== '' && !(Array.isArray(obj[key]) && obj[key].length === 0)) {
      result[key] = obj[key]
    }
  }

  return result
}

// Hàm tiện ích để tạo item
export const createItem = ({
  id,
  title,
  url,
  icon,
  premissions,
  type = 'item',
  breadcrumbs = false
}: {
  id: string
  title: string
  url: string
  icon?: any
  premissions: string[]
  type?: 'item'
  breadcrumbs?: boolean
}): MenuItem => ({
  id,
  title,
  type,
  url,
  icon,
  breadcrumbs,
  premissions
})

// Hàm tiện ích để tạo nhóm collapse
export const createCollapseGroup = ({
  id,
  title,
  icon,
  premissions,
  children,
  iconActive = null
}: {
  id: string
  title: string
  icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<Icon>> | RemixiconComponentType
  premissions: string[]
  children: MenuItem[]
  iconActive?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<Icon>> | RemixiconComponentType | null
}): MenuItem => ({
  id,
  title,
  type: 'collapse',
  icon,
  iconActive,
  premissions,
  children
})
