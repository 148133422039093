import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { ShippingLinesType } from '../../../types/shippingLines'
import { axiosBaseQuery } from '../../baseQuery'

export const shippingLinesApi = createApi({
  reducerPath: 'shippingLinesApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['ShippingLines'],
  endpoints: (builder) => ({
    getListShippingLines: builder.query({
      query: (params) => ({
        url: NetWork.shippingLines,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: ShippingLinesType[] }>) =>
          result ? [{ type: 'ShippingLines', id: 'LIST' }] : []
      })
    }),
    getShippingLinesById: builder.query({
      query: (params: { shippingLinesId: number }) => ({
        url: NetWork.shippingLinesId(params.shippingLinesId),
        method: GET
      })
    }),
    addShippingLines: builder.mutation({
      query: (data) => ({
        url: NetWork.shippingLines,
        method: POST,
        data: data
      })
    }),
    updateShippingLines: builder.mutation({
      query: (data) => ({
        url: NetWork.shippingLinesId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteShippingLines: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.shippingLines,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'ShippingLines', id: 'LIST' }] // Vô hiệu hóa tag 'ShippingLines' với id 'LIST' để gọi lại getListShippingLines
      })
    }),
    changeStatusshippingLines: builder.mutation({
      query: (data: { shippingLinesId: number; params: { isActive: boolean } }) => ({
        url: NetWork.shippingLinesId(data.shippingLinesId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListShippingLinesQuery,
  useDeleteShippingLinesMutation,
  useAddShippingLinesMutation,
  useGetShippingLinesByIdQuery,
  useUpdateShippingLinesMutation,
  useChangeStatusshippingLinesMutation
} = shippingLinesApi
