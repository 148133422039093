import { IS_DEV } from '../../common/contants'
import managers from './manager'
import utilities from './utilities'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = () => {
  const listDEV = [utilities]
  const listStg = [managers]
  return IS_DEV ? [...listStg, ...listDEV] : listStg
}

export default menuItems
