import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { CostOliType } from '../../../../types/genusHistory/costOli'
import { axiosBaseQuery } from '../../../baseQuery'

export const costOliApi = createApi({
  reducerPath: 'costOliApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['CostOli'],
  endpoints: (builder) => ({
    getListCostOli: builder.query({
      query: (params) => ({
        url: NetWork.costOli,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CostOliType[] }>) =>
          result ? [{ type: 'CostOli', id: 'LIST' }] : []
      })
    }),
    getCostOliById: builder.query({
      query: (params: { costOliId: number }) => ({
        url: NetWork.costOliId(params.costOliId),
        method: GET
      })
    }),
    addCostOli: builder.mutation({
      query: (data) => ({
        url: NetWork.costOli,
        method: POST,
        data: data
      })
    }),
    updateCostOli: builder.mutation({
      query: (data) => ({
        url: NetWork.costOliId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCostOli: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.costOli,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'CostOli', id: 'LIST' }] // Vô hiệu hóa tag 'CostOli' với id 'LIST' để gọi lại getListCostOli
      })
    }),
    changeStatusCostOli: builder.mutation({
      query: (data: { costOliId: number; params: { isActive: boolean } }) => ({
        url: NetWork.costOliId(data.costOliId),
        method: PATCH,
        params: data.params
      })
    }),
    exportExcelCostOli: builder.query({
      query: () => ({
        url: NetWork.costOliExportExcel,
        method: GET,
        // responseType: 'blob' // important
        responseType: 'arraybuffer'
        // headers: {
        //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //   // responseType: 'arraybuffer'
        // },
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCostOliQuery,
  useDeleteCostOliMutation,
  useAddCostOliMutation,
  useGetCostOliByIdQuery,
  useUpdateCostOliMutation,
  useChangeStatusCostOliMutation,
  useExportExcelCostOliQuery,
  useLazyExportExcelCostOliQuery
} = costOliApi
