const ROUTES = {
  HOME: '/',
  DEFAULT: 'default',
  INDEX: 'index',
  DASHBOARD: 'dashboard',
  ORDER: 'order',
  ORDER_DETAIL: 'order-detail',
  LOGIN: 'login',
  NOT_AUTHORIZED: 'not-authorized',
  SAMPLE_PAGE: 'sample-page',
  UTILS: 'utils',
  // Add more routes here as needed
  UTILS_CHILD: {
    TYPOGRAPHY: 'util-typography',
    COLOR: 'util-color',
    SHADOW: 'util-shadow',
    INPUT: 'util-input'
    // Add more utility routes here as needed
  },
  STATISTICAL: 'statistical',
  STATISTICAL_CHILD: {
    REQUEST: 'request',
    BASE_PRICE: 'base-price'
  },
  //Customer
  CUSTOMER: 'customer',
  CUSTOMER_ADD: 'customer-add',
  CUSTOMER_DETAIL: 'customer-detail',
  CUSTOMER_DETAIL_ID: 'customer-detail/:id',
  //Company
  COMPANY: 'company',
  COMPANY_CHILD: {
    CAR: 'car',
    CAR_DETAIL: 'car/:id',
    DRIVER: 'driver',
    DRIVER_DETAIL: 'driver/:id',
    COST_OLI: 'cost_oli',
    COST_REPAIR: 'cost_repair',
    COST_GENERAL: 'cost_general',
    COST_ROAD: 'cost_road',
    COST_BONUS: 'cost_bonus',
    NORMS_SALARY_MONTH: 'norms-salary-month',
    NORMS_SALARY_ORDERS: 'norms-salary-orders',
    NORMS_DEGRADATION: 'norms-degradation'
  },
  //Category
  CATEGORY: 'category',
  CATEGORY_CHILD: {
    STAFF: 'staff',
    STAFF_TYPE: 'staff-type',
    STAFF_DETAIL: 'staff/:id',
    PARTER: 'partner',
    COMPANY: 'company',
    COMPANY_CAR: 'car-company',
    SHELL_TYPE: 'shell-type',
    CAR_TYPE: 'car-type',
    SHIPPING_LINES: 'shipping-lines',
    CURRENCY: 'currency',
    ORDER_TYPE: 'order-type',
    PAYMENT_METHOD: 'payment-method',
    WORKER: 'worker',
    WORKER_DETAIL: 'worker/:id',
    SALARY_ADVANCE: 'salary-advance',
    SALARY_REFUND: 'salary-refund',
    SALARY_PAY_STAFF: 'salary-pay/staff',
    SALARY_PAY_EMPLOYEE: 'salary-pay/employee'
  }
}

export default ROUTES
