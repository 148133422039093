// assets
import { PERMISSION } from '../../constants'
import { categoryMenuItem } from './categoryAll'
import { companyMenuItem } from './company'
import { customerMenuItem } from './customer'
import { generalMenuItem } from './general'
import { staffAccountMenuItem } from './staffAccount'

const managers = {
  id: 'all-manager',
  // title: 'Quản lý',
  type: 'group',
  premissions: [
    PERMISSION.ADMIN,
    PERMISSION.GIAMDOC,
    PERMISSION.HCNS,
    PERMISSION.KETOAN,
    PERMISSION.SALE,
    PERMISSION.TUYENDUNG
  ],
  children: [...generalMenuItem, staffAccountMenuItem, customerMenuItem, ...companyMenuItem, ...categoryMenuItem]
}

export default managers
