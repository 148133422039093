import './assets/app.scss'
import { RouterProvider } from 'react-router-dom'
import NavigationScroll from './components/layout/NavigationScroll'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
// defaultTheme
import themes from './themes'
import { useAppSelector } from './app/hooks'
import { customTheme } from './app/selectedStore'
import router from './routers'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { DialogsProvider } from '@toolpad/core/useDialogs'

import dayjs from 'dayjs'
import 'dayjs/locale/vi' // Import ngôn ngữ tiếng Việt
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
// dayjs.Ls['vi'].weekdays = ['a', 'b','c','d','e','f','g'] // update locale
// dayjs.locale(dayjs.Ls['vi'])

// Tạo cấu hình đầy đủ cho locale
const updatedLocale = {
  ...dayjs.Ls['vi'], // Kế thừa các giá trị từ locale gốc 'vi'
  // weekdays: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'], // Tên đầy đủ các ngày
  // weekdaysShort: ['CN', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'], // Tên rút gọn
  weekdaysMin: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'] // Tên tối giản
}

// Ghi đè locale
dayjs.locale(updatedLocale)

dayjs.tz.setDefault('Asia/Ho_Chi_Minh') // Đặt múi giờ mặc định

//Chart
import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js'

// Register necessary components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Legend)

LicenseInfo.setLicenseKey(process.env.REACT_APP_PUBLIC_MUI_LICENSE_KEY || '')
function App() {
  const customization = useAppSelector(customTheme)
  return (
    <StyledEngineProvider injectFirst>
      <DialogsProvider>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <NavigationScroll>
              <RouterProvider router={router} />
            </NavigationScroll>
          </SnackbarProvider>
        </ThemeProvider>
      </DialogsProvider>
    </StyledEngineProvider>
  )
}

export default App
