import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { CustomerType } from '../../../types/customer'
import { axiosBaseQuery } from '../../baseQuery'

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['Customer'],
  endpoints: (builder) => ({
    getListCustomer: builder.query({
      query: (params) => ({
        url: NetWork.customer,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CustomerType[] }>) =>
          result ? [{ type: 'Customer', id: 'LIST' }] : []
      })
    }),
    getCustomerById: builder.query({
      query: (params: { customerId: number }) => ({
        url: NetWork.customerId(params.customerId),
        method: GET
      })
    }),
    addCustomer: builder.mutation({
      query: (data) => ({
        url: NetWork.customer,
        method: POST,
        data: data
      })
    }),
    updateCustomer: builder.mutation({
      query: (data) => ({
        url: NetWork.customerId(data.id),
        method: PUT,
        data: data
      })
    }),
    updateWarehouseCustomer: builder.mutation({
      query: (data) => ({
        url: NetWork.warehouseCustomer(data.id),
        method: PUT,
        data: data?.warehouses || []
      })
    }),
    deleteCustomer: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.customer,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'Customer', id: 'LIST' }] // Vô hiệu hóa tag 'Customer' với id 'LIST' để gọi lại getListCustomer
      })
    }),
    changeStatusCustomer: builder.mutation({
      query: (data: { customerId: number; params: { isActive: boolean } }) => ({
        url: NetWork.customerId(data.customerId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCustomerQuery,
  useDeleteCustomerMutation,
  useAddCustomerMutation,
  useGetCustomerByIdQuery,
  useUpdateCustomerMutation,
  useChangeStatusCustomerMutation,
  useUpdateWarehouseCustomerMutation
} = customerApi
