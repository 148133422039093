import { RiCaravanLine } from '@remixicon/react'
import { PERMISSION } from '../../constants'
import { createCollapseGroup, createItem } from '../../help'
import ROUTES from '../../routers/helpersRouter/constantRouter'
import { MenuItem } from '../../types'

const icons = {
  RiCaravanLine
}

export const companyMenuItem: MenuItem[] = [
  createCollapseGroup({
    id: 'company',
    title: 'Xe công ty/Lái xe',
    icon: icons.RiCaravanLine,
    premissions: [PERMISSION.ADMIN],
    children: [
      createItem({
        id: 'company-car',
        title: 'Xe công ty',
        url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.CAR}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createItem({
        id: 'driver-car',
        title: 'Lái xe',
        url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.DRIVER}`,
        premissions: [PERMISSION.ADMIN]
      }),
      createCollapseGroup({
        id: 'company-genus-history',
        title: 'Lịch sử chi',
        premissions: [PERMISSION.ADMIN],
        children: [
          createItem({
            id: 'company-genus-history-oli',
            title: 'Dầu không khoán',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.COST_OLI}`,
            premissions: [PERMISSION.ADMIN]
          }),
          createItem({
            id: 'company-edit-repair',
            title: 'Sửa chữa',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.COST_REPAIR}`,
            premissions: [PERMISSION.ADMIN]
          }),
          createItem({
            id: 'company-cost-general',
            title: 'Quản lý chung',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.COST_GENERAL}`,
            premissions: [PERMISSION.ADMIN]
          }),
          createItem({
            id: 'company-cost-road',
            title: 'Tiền đường',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.COST_ROAD}`,
            premissions: [PERMISSION.ADMIN]
          }),
          createItem({
            id: 'company-cost-bonus',
            title: 'Thưởng',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.COST_BONUS}`,
            premissions: [PERMISSION.ADMIN]
          })
        ]
      }),
      createCollapseGroup({
        id: 'company-norms',
        title: 'Định mức thanh toán',
        premissions: [PERMISSION.ADMIN],
        children: [
          createItem({
            id: 'company-norms-salary-month',
            title: 'Lương tháng',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.NORMS_SALARY_MONTH}`,
            premissions: [PERMISSION.ADMIN]
          }),
          createItem({
            id: 'company-norms-salary-order',
            title: 'Lương chuyến',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.NORMS_SALARY_ORDERS}`,
            premissions: [PERMISSION.ADMIN]
          }),
          createItem({
            id: 'company-norms-degradation',
            title: 'Định mức tiêu hao xe',
            url: `/${ROUTES.COMPANY}/${ROUTES.COMPANY_CHILD.NORMS_DEGRADATION}`,
            premissions: [PERMISSION.ADMIN]
          })
        ]
      })
    ]
  })
]
