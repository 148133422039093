import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { CarTType } from '../../../types/car'
import { axiosBaseQuery } from '../../baseQuery'

export const carTypeApi = createApi({
  reducerPath: 'carTypeApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['CarType'],
  endpoints: (builder) => ({
    getListCarType: builder.query({
      query: (params) => ({
        url: NetWork.carType,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CarTType[] }>) => (result ? [{ type: 'CarType', id: 'LIST' }] : [])
      })
    }),
    getCarTypeById: builder.query({
      query: (params: { carTypeId: number }) => ({
        url: NetWork.carTypeId(params.carTypeId),
        method: GET
      })
    }),
    addCarType: builder.mutation({
      query: (data) => ({
        url: NetWork.carType,
        method: POST,
        data: data
      })
    }),
    updateCarType: builder.mutation({
      query: (data) => ({
        url: NetWork.carTypeId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCarType: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.carType,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'CarType', id: 'LIST' }] // Vô hiệu hóa tag 'CarType' với id 'LIST' để gọi lại getListCarType
      })
    }),
    changeStatusCarType: builder.mutation({
      query: (data: { carTypeId: number; params: { isActive: boolean } }) => ({
        url: NetWork.carTypeId(data.carTypeId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCarTypeQuery,
  useDeleteCarTypeMutation,
  useAddCarTypeMutation,
  useGetCarTypeByIdQuery,
  useUpdateCarTypeMutation,
  useChangeStatusCarTypeMutation
} = carTypeApi
