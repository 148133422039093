import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { ShellTType } from '../../../types/shell'
import { axiosBaseQuery } from '../../baseQuery'

export const shellTypeApi = createApi({
  reducerPath: 'shellTypeApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['ShellType'],
  endpoints: (builder) => ({
    getListShellType: builder.query({
      query: (params) => ({
        url: NetWork.shellType,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: ShellTType[] }>) =>
          result ? [{ type: 'ShellType', id: 'LIST' }] : []
      })
    }),
    getShellTypeById: builder.query({
      query: (params: { shellTypeId: number }) => ({
        url: NetWork.shellTypeId(params.shellTypeId),
        method: GET
      })
    }),
    addShellType: builder.mutation({
      query: (data) => ({
        url: NetWork.shellType,
        method: POST,
        data: data
      })
    }),
    updateShellType: builder.mutation({
      query: (data) => ({
        url: NetWork.shellTypeId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteShellType: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.shellType,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'ShellType', id: 'LIST' }] // Vô hiệu hóa tag 'ShellType' với id 'LIST' để gọi lại getListShellType
      })
    }),
    changeStatusShellType: builder.mutation({
      query: (data: { shellTypeId: number; params: { isActive: boolean } }) => ({
        url: NetWork.shellTypeId(data.shellTypeId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListShellTypeQuery,
  useDeleteShellTypeMutation,
  useAddShellTypeMutation,
  useGetShellTypeByIdQuery,
  useUpdateShellTypeMutation,
  useChangeStatusShellTypeMutation
} = shellTypeApi
