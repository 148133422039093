import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { NormsDegradationType } from '../../../../types/norms/normsDegradation'
import { axiosBaseQuery } from '../../../baseQuery'

export const normsDegradationApi = createApi({
  reducerPath: 'normsDegradationApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['NormsDegradation'],
  endpoints: (builder) => ({
    getListNormsDegradation: builder.query({
      query: (params) => ({
        url: NetWork.normsDegradation,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: NormsDegradationType[] }>) =>
          result ? [{ type: 'NormsDegradation', id: 'LIST' }] : []
      })
    }),
    getNormsDegradationById: builder.query({
      query: (params: { normsDegradationId: number }) => ({
        url: NetWork.normsDegradationId(params.normsDegradationId),
        method: GET
      })
    }),
    addNormsDegradation: builder.mutation({
      query: (data) => ({
        url: NetWork.normsDegradation,
        method: POST,
        data: data
      })
    }),
    updateNormsDegradation: builder.mutation({
      query: (data) => ({
        url: NetWork.normsDegradationId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteNormsDegradation: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.normsDegradation,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'NormsDegradation', id: 'LIST' }] // Vô hiệu hóa tag 'NormsDegradation' với id 'LIST' để gọi lại getListNormsDegradation
      })
    }),
    changeStatusNormsDegradation: builder.mutation({
      query: (data: { normsDegradationId: number; params: { isActive: boolean } }) => ({
        url: NetWork.normsDegradationId(data.normsDegradationId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListNormsDegradationQuery,
  useDeleteNormsDegradationMutation,
  useAddNormsDegradationMutation,
  useGetNormsDegradationByIdQuery,
  useUpdateNormsDegradationMutation,
  useChangeStatusNormsDegradationMutation
} = normsDegradationApi
