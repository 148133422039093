import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { CostBonusType } from '../../../../types/genusHistory/costBonus'
import { axiosBaseQuery } from '../../../baseQuery'

export const costBonusApi = createApi({
  reducerPath: 'costBonusApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['CostBonus'],
  endpoints: (builder) => ({
    getListCostBonus: builder.query({
      query: (params) => ({
        url: NetWork.costBonus,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CostBonusType[] }>) =>
          result ? [{ type: 'CostBonus', id: 'LIST' }] : []
      })
    }),
    getCostBonusById: builder.query({
      query: (params: { costBonusId: number }) => ({
        url: NetWork.costBonusId(params.costBonusId),
        method: GET
      })
    }),
    addCostBonus: builder.mutation({
      query: (data) => ({
        url: NetWork.costBonus,
        method: POST,
        data: data
      })
    }),
    updateCostBonus: builder.mutation({
      query: (data) => ({
        url: NetWork.costBonusId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCostBonus: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.costBonus,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'CostBonus', id: 'LIST' }] // Vô hiệu hóa tag 'CostBonus' với id 'LIST' để gọi lại getListCostBonus
      })
    }),
    changeStatusCostBonus: builder.mutation({
      query: (data: { costBonusId: number; params: { isActive: boolean } }) => ({
        url: NetWork.costBonusId(data.costBonusId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCostBonusQuery,
  useDeleteCostBonusMutation,
  useAddCostBonusMutation,
  useGetCostBonusByIdQuery,
  useUpdateCostBonusMutation,
  useChangeStatusCostBonusMutation
} = costBonusApi
