import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { NormSalaryOrderType } from '../../../../types/norms/normSalaryOrder'
import { axiosBaseQuery } from '../../../baseQuery'

export const normSalaryOrderApi = createApi({
  reducerPath: 'normSalaryOrderApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['NormSalaryOrder'],
  endpoints: (builder) => ({
    getListNormSalaryOrder: builder.query({
      query: (params) => ({
        url: NetWork.normSalaryOrder,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: NormSalaryOrderType[] }>) =>
          result ? [{ type: 'NormSalaryOrder', id: 'LIST' }] : []
      })
    }),
    getNormSalaryOrderById: builder.query({
      query: (params: { normSalaryOrderId: number }) => ({
        url: NetWork.normSalaryOrderId(params.normSalaryOrderId),
        method: GET
      })
    }),
    addNormSalaryOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.normSalaryOrder,
        method: POST,
        data: data
      })
    }),
    updateNormSalaryOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.normSalaryOrderId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteNormSalaryOrder: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.normSalaryOrder,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'NormSalaryOrder', id: 'LIST' }] // Vô hiệu hóa tag 'NormSalaryOrder' với id 'LIST' để gọi lại getListNormSalaryOrder
      })
    }),
    changeStatusNormSalaryOrder: builder.mutation({
      query: (data: { normSalaryOrderId: number; params: { isActive: boolean } }) => ({
        url: NetWork.normSalaryOrderId(data.normSalaryOrderId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListNormSalaryOrderQuery,
  useDeleteNormSalaryOrderMutation,
  useAddNormSalaryOrderMutation,
  useGetNormSalaryOrderByIdQuery,
  useUpdateNormSalaryOrderMutation,
  useChangeStatusNormSalaryOrderMutation
} = normSalaryOrderApi
