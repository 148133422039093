import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { COLORS } from '../../common/colors'

interface CustomizedButtonProps extends ButtonProps {
  label: string
  onClick?: () => void
}

const BootstrapButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '7px 14px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  borderRadius: 6,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:hover': {
    backgroundColor: COLORS.main,
    borderColor: COLORS.main,
    boxShadow: 'none'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: COLORS.main,
    borderColor: COLORS.main
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
  }
})

// const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
//   color: theme.palette.primary.main,
//   backgroundColor: theme.palette.primary.main,
//   '&:hover': {
//     backgroundColor: purple[700]
//   }
// }))

const CustomizedButton: React.FC<CustomizedButtonProps> = ({ label, variant = 'contained', onClick, ...props }) => {
  return (
    <BootstrapButton variant={variant} disableRipple onClick={onClick} {...props}>
      {label}
    </BootstrapButton>
  )
}

export default CustomizedButton

export const filterButtonWhiteStyles = {
  backgroundColor: COLORS.white,
  borderColor: COLORS.borderNeutral,
  color: COLORS.black,
  ':hover': {
    backgroundColor: COLORS.white,
    color: COLORS.main
  }
}

export const filterButtonBlueMainStyles = {
  backgroundColor: COLORS.main,
  borderColor: COLORS.borderNeutral
}

export const filterButtonRedStyles = {
  backgroundColor: COLORS.white,
  borderColor: COLORS.red,
  color: COLORS.red,
  ':hover': {
    backgroundColor: COLORS.white,
    color: COLORS.red,
    borderColor: COLORS.red
  }
}
