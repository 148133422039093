import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { ShellTType } from '../../../types/shell'
import { axiosBaseQuery } from '../../baseQuery'

export const requestOrderApi = createApi({
  reducerPath: 'requestOrderApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['RequestOrder'],
  endpoints: (builder) => ({
    getListRequestOrder: builder.query({
      query: (params) => ({
        url: NetWork.requestOrder,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: ShellTType[] }>) =>
          result ? [{ type: 'RequestOrder', id: 'LIST' }] : []
      })
    }),
    getRequestOrderById: builder.query({
      query: (params: { requestOrderId: number }) => ({
        url: NetWork.requestOrderId(params.requestOrderId),
        method: GET
      })
    }),
    addRequestOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.requestOrder,
        method: POST,
        data: data
      })
    }),
    updateRequestOrder: builder.mutation({
      query: (data) => ({
        url: NetWork.requestOrderId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteRequestOrder: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.requestOrder,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'RequestOrder', id: 'LIST' }] // Vô hiệu hóa tag 'RequestOrder' với id 'LIST' để gọi lại getListRequestOrder
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListRequestOrderQuery,
  useDeleteRequestOrderMutation,
  useAddRequestOrderMutation,
  useGetRequestOrderByIdQuery,
  useUpdateRequestOrderMutation
} = requestOrderApi
