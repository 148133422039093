import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { CostGeneralType } from '../../../../types/genusHistory/costGeneral'
import { axiosBaseQuery } from '../../../baseQuery'

export const costGeneralApi = createApi({
  reducerPath: 'costGeneralApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['CostGeneral'],
  endpoints: (builder) => ({
    getListCostGeneral: builder.query({
      query: (params) => ({
        url: NetWork.costGeneral,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CostGeneralType[] }>) =>
          result ? [{ type: 'CostGeneral', id: 'LIST' }] : []
      })
    }),
    getCostGeneralById: builder.query({
      query: (params: { costGeneralId: number }) => ({
        url: NetWork.costGeneralId(params.costGeneralId),
        method: GET
      })
    }),
    addCostGeneral: builder.mutation({
      query: (data) => ({
        url: NetWork.costGeneral,
        method: POST,
        data: data
      })
    }),
    updateCostGeneral: builder.mutation({
      query: (data) => ({
        url: NetWork.costGeneralId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCostGeneral: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.costGeneral,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'CostGeneral', id: 'LIST' }] // Vô hiệu hóa tag 'CostGeneral' với id 'LIST' để gọi lại getListCostGeneral
      })
    }),
    changeStatusCostGeneral: builder.mutation({
      query: (data: { costGeneralId: number; params: { isActive: boolean } }) => ({
        url: NetWork.costGeneralId(data.costGeneralId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCostGeneralQuery,
  useDeleteCostGeneralMutation,
  useAddCostGeneralMutation,
  useGetCostGeneralByIdQuery,
  useUpdateCostGeneralMutation,
  useChangeStatusCostGeneralMutation
} = costGeneralApi
