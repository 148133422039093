export const COLORS = {
  white: '#fff',
  black: '#000',
  text: '#4E4E4E',
  textInput: '#222222',
  main: '#0088F5',
  bgButton: '#203461',
  lightRed: '#FEF0F4',
  red: '#E30736',
  lightGreen: '#EEFBF4',
  green: '#4DBC6C',
  borderNeutral: '#E9E9E9',
  //gray
  gray: '#F4F4F4'
}
