import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { OrderTType } from '../../../types/orderType'
import { axiosBaseQuery } from '../../baseQuery'

export const orderTypeApi = createApi({
  reducerPath: 'orderTApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['OrderType'],
  endpoints: (builder) => ({
    getListOrderType: builder.query({
      query: (params) => ({
        url: NetWork.orderType,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: OrderTType[] }>) =>
          result ? [{ type: 'OrderType', id: 'LIST' }] : []
      })
    }),
    getOrderTypeById: builder.query({
      query: (params: { orderTypeId: number }) => ({
        url: NetWork.orderTypeId(params.orderTypeId),
        method: GET
      })
    }),
    addOrderType: builder.mutation({
      query: (data) => ({
        url: NetWork.orderType,
        method: POST,
        data: data
      })
    }),
    updateOrderType: builder.mutation({
      query: (data) => ({
        url: NetWork.orderTypeId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteOrderType: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.orderType,
        method: DELETE,
        data: data
      })
    }),
    changeStatusOrderType: builder.mutation({
      query: (data: { orderTypeId: number; params: { isActive: boolean } }) => ({
        url: NetWork.orderTypeId(data.orderTypeId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListOrderTypeQuery,
  useDeleteOrderTypeMutation,
  useAddOrderTypeMutation,
  useGetOrderTypeByIdQuery,
  useUpdateOrderTypeMutation,
  useChangeStatusOrderTypeMutation
} = orderTypeApi
