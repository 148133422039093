import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../../common/contants'
import { ReponseData2 } from '../../../../types'
import { CostRepairType } from '../../../../types/genusHistory/costRepair'
import { axiosBaseQuery } from '../../../baseQuery'

export const costRepairApi = createApi({
  reducerPath: 'costRepairApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['CostRepair'],
  endpoints: (builder) => ({
    getListCostRepair: builder.query({
      query: (params) => ({
        url: NetWork.costRepair,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CostRepairType[] }>) =>
          result ? [{ type: 'CostRepair', id: 'LIST' }] : []
      })
    }),
    getCostRepairById: builder.query({
      query: (params: { costRepairId: number }) => ({
        url: NetWork.costRepairId(params.costRepairId),
        method: GET
      })
    }),
    addCostRepair: builder.mutation({
      query: (data) => ({
        url: NetWork.costRepair,
        method: POST,
        data: data
      })
    }),
    updateCostRepair: builder.mutation({
      query: (data) => ({
        url: NetWork.costRepairId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCostRepair: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.costRepair,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'CostRepair', id: 'LIST' }] // Vô hiệu hóa tag 'CostRepair' với id 'LIST' để gọi lại getListCostRepair
      })
    }),
    changeStatusCostRepair: builder.mutation({
      query: (data: { costRepairId: number; params: { isActive: boolean } }) => ({
        url: NetWork.costRepairId(data.costRepairId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCostRepairQuery,
  useDeleteCostRepairMutation,
  useAddCostRepairMutation,
  useGetCostRepairByIdQuery,
  useUpdateCostRepairMutation,
  useChangeStatusCostRepairMutation
} = costRepairApi
