import { createApi } from '@reduxjs/toolkit/query/react'
import { NetWork } from '../../../common/apiKey'
import { DELETE, GET, PATCH, POST, PUT } from '../../../common/contants'
import { ReponseData2 } from '../../../types'
import { CompanyCarType } from '../../../types/companyCar'
import { axiosBaseQuery } from '../../baseQuery'

export const companyCarApi = createApi({
  reducerPath: 'companyCarApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['CompanyCar'],
  endpoints: (builder) => ({
    getListCompanyCar: builder.query({
      query: (params) => ({
        url: NetWork.companyCar,
        method: GET,
        params: params,
        providesTags: (result: ReponseData2<{ rows: CompanyCarType[] }>) =>
          result ? [{ type: 'CompanyCar', id: 'LIST' }] : []
      })
    }),
    getCompanyCarById: builder.query({
      query: (params: { companyCarId: number }) => ({
        url: NetWork.companyCarId(params.companyCarId),
        method: GET
      })
    }),
    addCompanyCar: builder.mutation({
      query: (data) => ({
        url: NetWork.companyCar,
        method: POST,
        data: data
      })
    }),
    updateCompanyCar: builder.mutation({
      query: (data) => ({
        url: NetWork.companyCarId(data.id),
        method: PUT,
        data: data
      })
    }),
    deleteCompanyCar: builder.mutation({
      query: (data: { ids: Array<number | string> }) => ({
        url: NetWork.companyCar,
        method: DELETE,
        data: data
        // invalidatesTags: [{ type: 'CompanyCar', id: 'LIST' }] // Vô hiệu hóa tag 'CompanyCar' với id 'LIST' để gọi lại getListCompanyCar
      })
    }),
    changeStatusCompanyCar: builder.mutation({
      query: (data: { paymentMethodId: number; params: { isActive: boolean } }) => ({
        url: NetWork.companyCarId(data.paymentMethodId),
        method: PATCH,
        params: data.params
      })
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetListCompanyCarQuery,
  useDeleteCompanyCarMutation,
  useAddCompanyCarMutation,
  useGetCompanyCarByIdQuery,
  useUpdateCompanyCarMutation,
  useChangeStatusCompanyCarMutation
} = companyCarApi
