import colors from '../assets/scss/_themes-vars.module.scss'
export const POST = 'POST'
export const PUT = 'PUT'
export const GET = 'GET'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'

export const LICENSE_KEY = process.env.REACT_APP_PUBLIC_MUI_LICENSE_KEY || ''
export const IS_DEV = process.env.REACT_APP_DEV ?? false

export const STATUS_SUCCESS = 'success'
export const STATUS_ERROR = 'error'

export const PADDINGMAINLAYOUT = '20px'

export const OPTIONGENDER = [
  { value: 'MALE', label: 'Nam' },
  { value: 'FEMALE', label: 'Nữ' },
  { value: 'OTHER', label: 'Khác' }
]

export const OPTIONSTATUS = [
  { value: '1', label: 'ON' },
  { value: '0', label: 'OFF' }
]

export const OPTIONTYPECUSTOMER = [
  { value: 'PERSON', label: 'Cá nhân' },
  { value: 'COMPANY', label: 'Công ty' }
]

export const KEY_OPTION__SALARY = {
  month: 'MONTH',
  order: 'ORDER'
}

export const OPTIONTYPESALARY = [
  { value: KEY_OPTION__SALARY.month, label: 'Lương tháng' },
  { value: KEY_OPTION__SALARY.order, label: 'Lương chuyến' }
]

export const TYPE_UPLOAD_IMG = {
  cccdStaff: 'staff/cccd', //Căn cước nhân viên
  cccdDriver: 'driver/cccd', //Căn cước lái
  gplxDriver: 'driver/gplx', //Giấy phép lái xe
  portraitDriver: 'driver/portrait', //Ảnh chân dung lái xe
  certDriver: 'driver/cert' //Chứng chỉ lái xe
}

export const OPTION_UPLOAD_IMG_DRIVER = [
  { value: TYPE_UPLOAD_IMG.cccdDriver, label: 'Ảnh CCCD/CMND' },
  { value: TYPE_UPLOAD_IMG.certDriver, label: 'Ảnh chứng chỉ lái xe' },
  { value: TYPE_UPLOAD_IMG.portraitDriver, label: 'Ảnh chân dung lái xe' },
  { value: TYPE_UPLOAD_IMG.gplxDriver, label: 'Ảnh giấy phép lái xe' }
]

// export const OPTIONSPOSITION = [
//   { value: 'ADMIN', label: 'Admin' },
//   { value: 'GIAMDOC', label: 'Giám đốc' },
//   { value: 'KETOAN', label: 'Kế toán' },
//   { value: 'HCNS', label: 'Hành chính nhân sự' },
//   { value: 'TUYENDUNG', label: 'Tuyển dụng' },
//   { value: 'SALE', label: 'Bán hàng' }
// ]

export const OPTIONONOFF = [
  { value: '0', label: 'Offline' },
  { value: '1', label: 'Online' }
]

export const OPTIONOPENOFF = [
  { label: 'Mở', value: 'true' },
  { label: 'Đóng', value: 'false' }
]

export const OPTIONSTATUSWORK = [
  { value: 'WORKING', label: 'Đang làm việc' },
  { value: 'OUT', label: 'Đã nghỉ việc' },
  { value: 'STOP', label: 'Tạm nghỉ' }
]

export const OPTIONTYPEWORK = [
  { value: 'PROBATION', label: 'Thử việc' },
  { value: 'OFFICIAL', label: 'Chính thức' },
  { value: 'PART_TIME', label: 'Bán thời gian' }
]

export const OPTIONSTTWORKER = [
  { value: 'WAITING_FOR_INTERVIEW', label: 'Chờ phỏng vấn' },
  { value: 'INTERVIEW_FAILED', label: 'Phỏng vấn trượt' },
  { value: 'WORKING', label: 'Đang làm việc' },
  { value: 'WAITING_FOR_JOB', label: 'Đang chờ việc' },
  { value: 'LENT_TO_PARTNER', label: 'Cho vendor mượn' },
  { value: 'RESIGNED', label: 'Đã nghỉ việc' },
  { value: 'BORROWED_FROM_PARTNER', label: 'Mượn của vendor' }
]

export const STATUS_WORKING_EMPLOYEE = [
  { value: 'WAITING', label: 'Chờ phỏng vấn' },
  { value: 'FAIL', label: 'Phỏng vấn trượt' },
  { value: 'IN_HOME', label: 'Chờ giao việc' },
  { value: 'IN_COMPANY', label: 'Trong công ty' },
  { value: 'IN_PARTNER', label: 'Cho vendor mượn' },
  { value: 'OUT', label: 'Đã nghỉ việc' }
]

export const STATUS_ADVANCE_SALARY = [
  { value: 'WAITING_ACCEPT', label: 'Chờ duyệt', bg: 'yellow', color: 'black' },
  { value: 'ACCEPTED', label: 'Đã duyệt', bg: colors.successDark, color: 'white' },
  { value: 'REJECTED', label: 'Từ chối', bg: 'red', color: 'white' }
]

export const OPTION_COMPLETION = [
  { value: 'true', label: 'Đã hoàn ứng' },
  { value: 'false', label: 'Chưa hoàn' }
]

export const OPTION_HUMAN_RESOURCES = [
  { value: 'STAFF', label: 'Nhân viên' },
  { value: 'EMPLOYEE', label: 'Công nhân' }
]

export const OPTIONTYPEOLI = [
  { value: 'BAREM', label: 'Dầu khoán' },
  { value: 'ACTUAL', label: 'Dầu chuyến' }
]

//Filter advanced
export const OPTION_FILTER_CUSTOMER = [
  { value: 'all', label: 'Tất cả' },
  { value: 'identifier', label: 'Mã định danh' },
  { value: 'customerName', label: 'Tên khách hàng' },
  { value: 'shortName', label: 'Tên viết tắt' },
  { value: 'address', label: 'Địa chỉ' },
  { value: 'email', label: 'Email' },
  { value: 'firstSettlementDate', label: 'Ngày đầu chốt sổ' },
  { value: 'lastSettlementDate', label: 'Ngày cuối chốt sổ' }
]

export const OPTION_FILTER_COMPANYCAR = [
  { value: 'all', label: 'Tất cả' },
  { value: 'licensePlate', label: 'Biển số xe' },
  { value: 'tractorType', label: 'Loại đầu kéo' },
  { value: 'trailerNumber', label: 'Số Rơ Mooc' },
  { value: 'semiTrailerType', label: 'Loại sơ mi' },
  { value: 'driverName', label: 'Tên lái xe' },
  { value: 'phoneNumber', label: 'Số điện thoại' }
]

export const OPTION_FILTER_STAFF = [
  { value: 'all', label: 'Tất cả' },
  { value: 'name', label: 'Tên nhân viên' },
  { value: 'phoneNumber', label: 'Số điện thoại' },
  // { value: 'gender', label: 'Giới tính' },
  { value: 'address', label: 'Địa chỉ' },
  { value: 'username', label: 'Tài khoản' }
]

export const OPTION_CUSTOMER_FAKE = [
  { value: '1', label: 'Anh Dũng' },
  { value: '2', label: 'Cao Nguyễn' }
]
