import { forwardRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, matchPath, useLocation } from 'react-router-dom'

// material-ui
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

// assets
import { menuOpen, setMenu } from '../../../../../../app/features/customization/customizationSlice'
import { useAppSelector } from '../../../../../../app/hooks'
import { customTheme } from '../../../../../../app/selectedStore'
import { MenuItem } from '../../../../../../types'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

type ListItemProps = {
  component: React.ElementType
} & Omit<React.ComponentProps<typeof ListItemButton>, 'component'>

const NavItem = ({ item, level }: { item: MenuItem; level: number }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const customization = useAppSelector(customTheme)
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const selected = customization?.isOpen?.findIndex((id) => id === item.id) > -1
  const openDrawer = customization?.opened

  const Icon = item.icon
  const itemIcon = Icon ? (
    <Icon size='1.3rem' />
  ) : (
    // <FiberManualRecordIcon
    //   sx={{
    //     width: selected ? 8 : 6,
    //     height: selected ? 8 : 6
    //   }}
    //   fontSize={level > 0 ? 'inherit' : 'medium'}
    // />
    <></>
  )

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const listItemProps: ListItemProps = {
    component: forwardRef((props: any, ref: any) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
  // if (item?.external) {
  //   listItemProps = { component: 'a', href: item.url, target: itemTarget }
  // }

  const itemHandler = (id: string) => {
    dispatch(menuOpen({ id }))
    if (matchesSM) dispatch(setMenu({ opened: false }))
  }

  // active menu item on page load
  useEffect(() => {
    if (item.url) {
      const basePath = level < 2 && item.url.split('/').slice(0, 2).join('/')
      const baseMatch = matchPath(
        { path: `${basePath}/*` }, // Chấp nhận các route con
        pathname
      )
      const exactMatch = matchPath(item.url, pathname)
      const dynamicMatch = matchPath(item.url, pathname.replace(/\/\d+$/, ''))
      if (exactMatch || dynamicMatch || baseMatch) {
        dispatch(menuOpen({ id: item.id }))
      }
    }
  }, [pathname, item.url, dispatch, item.id, openDrawer])

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? (openDrawer ? 'transparent !important' : 'inherit') : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: openDrawer || matchDownMd ? `${level * 20}px` : '14px',
        '&.Mui-selected': {
          backgroundColor: level > 1 && !openDrawer ? '#E0F3FF' : theme.palette.primary.main, // Màu khi được chọn
          '& .MuiListItemIcon-root': {
            color: 'white', // Đổi màu icon khi được chọn,
            display: level > 1 && !openDrawer && !matchDownMd ? 'none' : 'block'
          },
          '&:hover': {
            backgroundColor: level > 1 && !openDrawer ? '#E0F3FF' : '#0075E8' // Màu khi hover trong trạng thái selected
          }
        },
        '&.Mui-focusVisible': {
          backgroundColor: theme.palette.primary.main // Màu khi focus
        },
        '&:hover': {
          backgroundColor: '#E0F3FF' // Màu khi hover nếu không được chọn
        },
        '& .MuiListItemIcon-root': {
          display: level > 1 && !openDrawer && !matchDownMd ? 'none' : 'block'
        }
      }}
      selected={selected}
      onClick={() => itemHandler(item?.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      {(openDrawer || level > 1 || matchDownMd) && (
        <ListItemText
          primary={
            <Typography variant={'body1'} color={selected ? (level > 1 ? theme.palette.primary.main : 'white') : ''}>
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography variant='caption' sx={{ ...theme.typography.subMenuCaption }} display='block' gutterBottom>
                {item.caption}
              </Typography>
            )
          }
        />
      )}
      {(openDrawer || level > 1 || matchDownMd) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  )
}

export default NavItem
