export const API_URL = process.env.REACT_APP_BASE_URL ?? ''
export const URL_FILE_EXCEL = process.env.REACT_APP_URL_FILE_EXCEL_STAFF ?? ''
export const URL_FILE_EXCEL_EMPLOYEE = process.env.REACT_APP_URL_FILE_EXCEL_EMPLOYEE ?? ''
export const URL_DOMAIN = process.env.REACT_APP_DOMAIN_URL ?? ''

export const NetWork = {
  //Admin
  login: 'auth/login',
  refresh_token: 'auth/refresh-token',

  //Account
  account: 'account',
  accountActive: (accountId: number) => `account/${accountId}/active`,
  account_changePass: 'account/change-password',
  changePass_acc: 'staff/change-password',
  role: 'role',

  //Pokemon
  pokemon: 'api/v2/',

  //Staff
  uploadCccdStaff: 'upload-image/staff/cccd',
  staff: 'staff',
  staffId: (staffId: number) => `staff/${staffId}`,
  staffActive: (staffId: number) => `staff/${staffId}/active`,
  staffHistory: `staff-history`,
  staffHistoryList: (staffId: number) => `staff-history/staff/${staffId}`,
  staffHistoryId: (staffHistoryId: number) => `staff-history/${staffHistoryId}`,
  staffSalary: `salary-staff`,
  staffSalaryList: (staffId: number) => `salary-staff/staff/${staffId}`,
  staffSalaryId: (staffSalaryId: number) => `salary-staff/${staffSalaryId}`,

  //Staff
  staffType: 'staff-type',
  staffTypeId: (staffTypeId: number) => `staff-type/${staffTypeId}`,

  //Employee
  employee: 'employee',
  employeeId: (employeeId: number) => `employee/${employeeId}`,
  employeeActive: (employeeId: number) => `employee/${employeeId}/active`,
  employeeHistory: `employee-history`,
  employeeHistoryList: (employeeId: number) => `employee-history/employee/${employeeId}`,
  employeeHistoryId: (employeeHistoryId: number) => `employee-history/${employeeHistoryId}`,
  employeeUpload: 'employee/upload',

  //Company
  company: 'company',
  companyId: (companyId: number) => `company/${companyId}`,

  //Customer
  customer: 'customer',
  customerId: (customerId: number) => `customer/${customerId}`,
  warehouseCustomer: (customerId: number) => `warehouse/${customerId}`,

  //Partner
  partner: 'partner',
  partnerId: (partnerId: number) => `partner/${partnerId}`,

  //SalaryAdvance
  salaryAdvance: 'salary-advance',
  salaryAdvanceId: (salaryAdvanceId: number) => `salary-advance/${salaryAdvanceId}`,
  salaryAdvanceStatus: (salaryAdvanceId: number) => `salary-advance/${salaryAdvanceId}/status-advance`,

  //SalaryRefund
  salaryRefund: 'salary-refund',
  salaryRefundId: (salaryRefundId: number) => `salary-refund/${salaryRefundId}`,
  salaryRefundStatus: (salaryRefundId: number) => `salary-refund/${salaryRefundId}/status-refund`,
  salaryRefundMultipleStatus: `salary-refund/complete-refund-multiple`,

  //SalaryPay
  salaryPay: 'salary',
  salaryPayUpload: 'salary/upload',
  salaryPayId: (salaryPayId: number) => `salary/${salaryPayId}`,
  salaryPayStatus: (salaryPayId: number) => `salary/${salaryPayId}/status-advance`,

  //Static
  staticStaffTotal: 'statistic/staff-total',
  staticStaffDetail: 'statistic/staff-detail',
  staticEmployeeTotal: 'statistic/employee-total',
  staticEmployeeDetail: 'statistic/employee-detail',
  staticSalaryAdvance: 'statistic/salary-advance',
  staticEmployeeByMonth: 'statistic/employee-by-month',

  //companyCar
  companyCar: 'my-car',
  companyCarId: (companyCarId: number) => `my-car/${companyCarId}`,

  //shellType
  shellType: 'container',
  shellTypeId: (shellTypeId: number) => `container/${shellTypeId}`,

  //carType
  carType: 'transport',
  carTypeId: (carTypeId: number) => `transport/${carTypeId}`,

  //shippingLines
  shippingLines: 'shipping-line',
  shippingLinesId: (shippingLinesId: number) => `shipping-line/${shippingLinesId}`,

  //paymentMethod
  paymentMethod: 'payment',
  paymentMethodId: (paymentMethodId: number) => `payment/${paymentMethodId}`,

  //currency
  currency: 'currency',
  currencyId: (currencyId: number) => `currency/${currencyId}`,

  //orderType
  orderType: 'order-type',
  orderTypeId: (orderTypeId: number) => `order-type/${orderTypeId}`,

  //requestOrder
  requestOrder: 'plan',
  requestOrderId: (requestOrderId: number) => `plan/${requestOrderId}`,

  //priceBase
  priceBase: 'price-base',
  priceBaseId: (priceBaseId: number) => `price-base/${priceBaseId}`,

  //Driver
  uploadDriver: (path: number) => `upload-image/${path}`,
  driver: 'driver',
  driverId: (driverId: number) => `driver/${driverId}`,
  driverActive: (driverId: number) => `driver/${driverId}/active`,

  //costOli
  costOli: 'cost-oil',
  costOliId: (costOliId: number) => `cost-oil/${costOliId}`,
  costOliExportExcel: 'cost-oil/export-excel',

  //costRepair
  costRepair: 'cost-repair',
  costRepairId: (costRepairId: number) => `cost-repair/${costRepairId}`,

  //costGeneral
  costGeneral: 'cost-general',
  costGeneralId: (costGeneralId: number) => `cost-general/${costGeneralId}`,

  //costRoad
  costRoad: 'cost-road',
  costRoadId: (costRoadId: number) => `cost-road/${costRoadId}`,

  //costBonus
  costBonus: 'cost-road',
  costBonusId: (costBonusId: number) => `cost-road/${costBonusId}`,

  //normsSalaryMonth
  normsSalaryMonth: 'norm-salary-month',
  normsSalaryMonthId: (normsSalaryMonthId: number) => `norm-salary-month/${normsSalaryMonthId}`,

  //normSalaryOrder
  normSalaryOrder: 'norm-salary-order',
  normSalaryOrderId: (normSalaryOrderId: number) => `norm-salary-order/${normSalaryOrderId}`,

  //normsDegradation
  normsDegradation: 'norm-degradation',
  normsDegradationId: (normsDegradationId: number) => `norm-degradation/${normsDegradationId}`
}
